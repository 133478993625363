<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="px-2">
        <ion-label slot="start" class="fs-3 fw-bold">{{ $t('companies') }}</ion-label>
        <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="closeModal"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="!isShow" class="px-2">
      <!-- show-skeleton-loading -->
      <ion-item v-for="(item, index) in 2" :key="index" lines="full" :detail="false" class="skeleton mt-3">
        <ion-thumbnail slot="start">
          <ion-skeleton-text class="avatar" :animated="true"></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 40%; height: 18px"
            ></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 35%; height: 30px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 100%; height: 35px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 50%; height: 20px"
            ></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-content>
    <ion-content v-if="isShow" style="--background: #eeeeee">
      <ion-list v-if="customer" class="pt-2 mb-4">
        <ion-radio-group
          :allow-empty-selection="false"
          :value="selectedCompany?.id || previousSelectedCompanyId"
          mode="md"
        >
          <ion-text class="pl-3 name text-primary">{{ $t('selectCompany.main_company') }}</ion-text>
          <ion-item
            class="card-company mt-2 ion-no-padding mb-3"
            style="margin-right: -4vw"
            lines="full"
            @click="onTapCustomer(customer)"
          >
            <div
              class="image-default mr-2 ml-3"
              :style="`background: ${theme.background}; color:${theme.color}`"
            >
              {{ getInitialName(customer.name) }}
            </div>
            <ion-label style="margin-top: -1px">
              <div class="fw-bold">{{ customer.name }}</div>
              <div class="my-1 text-primary">
                {{ customer.account_number }}
              </div>
              <div class="text-grey">{{ customer.address }}</div>
              <div v-if="customer.black_list" class="d-flex align-center mt-1">
                <div class="box-label d-flex align-center">
                  <ion-icon size="small" :icon="banOutline" color="danger"></ion-icon>
                  <span class="text-danger ml-1 fs-2 fw-500">{{ $t('black_listed') }}</span>
                </div>
              </div>
            </ion-label>
            <ion-radio slot="end" :value="customer.id" @ionFocus="onTapCustomer(customer)"></ion-radio>
          </ion-item>
          <div v-if="customer.branches?.length > 0" class="space-grey"></div>
          <div class="pl-3 name text-primary mt-3" v-if="customer.branches?.length > 0">
            {{ $t('selectCompany.branches') }}
          </div>
          <div v-for="company in customer.branches" :key="company.id">
            <ion-item
              lines="full"
              class="card-company mt-2 ion-no-padding mb-3"
              style="margin-right: -4vw"
              @click="onTapCustomer(company)"
            >
              <div
                class="image-default mr-2 ml-3"
                :style="`background: ${themeBranch.background}; color:${themeBranch.color}`"
              >
                {{ getInitialName(company.name ? company.name : company.branch) }}
              </div>
              <ion-label color="dark">
                <div class="fw-bold">{{ company.name ? company.name : company.branch }}</div>
                <div class="my-1 text-primary">
                  {{ company.account_number }}
                </div>
                <div class="text-grey">{{ company.address }}</div>
                <div v-if="customer.black_list" class="d-flex align-center mt-1">
                  <div class="box-label d-flex align-center">
                    <ion-icon size="small" :icon="banOutline" color="danger"></ion-icon>
                    <span class="text-danger ml-1 fs-2 fw-500">{{ $t('black_listed') }}</span>
                  </div>
                </div>
              </ion-label>
              <ion-radio slot="end" :value="company.id" @ionFocus="onTapCustomer(company)"></ion-radio>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="!isShow" class="my-3 px-2" style="--background: white">
        <ion-label>
          <p>
            <ion-skeleton-text
              class="skeleton-child mb-2"
              :animated="true"
              style="width: 100%; height: 40px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 100%; height: 40px"
            ></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-toolbar>
      <ion-toolbar v-else class="mb-3 px-2" style="--background: transparent">
        <ion-button
          :disabled="disableApplyButton"
          class="ion-margin-top foot-btn"
          expand="block"
          @click="applySelectedCustomer"
          >{{ $t('apply') }}</ion-button
        >
        <ion-button
          class="ion-margin-top foot-btn-outline"
          expand="block"
          fill="outline"
          @click="changeCustomer"
          >{{ $t('select_other_company') }}</ion-button
        >
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { handleRamdomBackground } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/sale/store/customer/actions';
import { ACTIONS as ACTIONS_NOTIFICATION } from '@/modules/sale/store/notification/actions';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/sale/store/payment/actions';
import Clevertap from '@/services/shared/helper/clevertap';
import { STORAGE_KEYS } from '@/storage';
import { banOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, inject } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions: mapActionsNotification } = createNamespacedHelpers('sale/notification');
const { mapActions, mapGetters } = createNamespacedHelpers('sale/customer');
const { mapActions: mapActionsPayment } = createNamespacedHelpers('sale/payment');
export default defineComponent({
  name: 'branch-selection',
  setup() {
    const ionRouter = inject('navManager');
    return {
      ionRouter,
      closeOutline,
      banOutline
    };
  },
  data() {
    return {
      showChangeButton: true,
      previousSelectedCompanyId: null,
      selectedCompany: null,
      customer: null,
      isShow: false,
      theme: [],
      themeBranch: [],
      selectedBranch: null,
      currentPage: null,
      prevPage: null
    };
  },
  props: {
    customerSelect: {
      type: Object,
      default: null
    },
    showChangeBtn: {
      type: Boolean,
      default: false
    }
  },
  inject: ['$storage'],
  async created() {
    this.theme = handleRamdomBackground();
    this.themeBranch = handleRamdomBackground();
  },
  async mounted() {
    this.currentPage = this.$route.path;
    this.prevPage = window.history?.state?.back;
    this.isShow = false;
    const customer = await this.$storage.getSelectCustomer();
    this.showChangeButton = this.showChangeBtn
      ? this.showChangeBtn
      : !this.$route.path.endsWith('/sale/select-customer');
    let company = await this.$storage.getSelectedCompany();
    this.selectedCompany = company;
    this.previousSelectedCompanyId = company ? company.id : null;
    await this[ACTIONS.GET_CUSTOMERS_BRANCH]({
      id: customer.id,
      tagged: true
    });
    const list = JSON.parse(JSON.stringify(this.customerBranch));
    this.customer = this.customerSelect
      ? this.customerSelect
      : list?.data.find((item) => item.id === customer.id);
    this.isShow = true;
    let isIncluded =
      this.customer?.branches.length > 0 && this.selectedCompany?.id !== this.customer?.id
        ? this.customer?.branches.some((branch) => branch.id === this.selectedCompany?.id)
        : this.selectedCompany?.id === this.customer?.id
        ? true
        : false;
    if (isIncluded) {
      this.onTapCustomer(this.selectedCompany);
    }
  },
  computed: {
    ...mapGetters(['customerBranch']),
    disableApplyButton() {
      let checkBranchSelected = false;
      let checkSelectedMain = this.selectedCompany?.id === this.customer?.id ? true : false;
      if (this.customer?.branches.length > 0 && !checkSelectedMain) {
        checkBranchSelected = this.customer?.branches.some(
          (branch) => branch.id === this.selectedCompany?.id
        );
      } else {
        checkBranchSelected = true;
      }
      return !checkBranchSelected && this.selectedBranch === null;
    }
  },
  methods: {
    ...mapActions([ACTIONS.UPDATE_SELECTED_CUSTOMER, ACTIONS.GET_CUSTOMERS_BRANCH]),
    ...mapActionsNotification([ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]),
    ...mapActionsPayment([ACTIONS_PAYMENT.REMOVE_SELECTED_PAYMENT_INVOICES]),

    async getNumberBadge(customerId) {
      await this[ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]({
        customerId: customerId
      });
    },
    getInitialName(name) {
      let initials = '';
      let names = name.split(' ');
      let nameCount = names.length > 2 ? 2 : names.length;
      for (let i = 0; i < nameCount; i++) {
        if (names[i]) {
          initials += names[i][0].toUpperCase();
        }
      }
      return initials;
    },
    async closeModal() {
      await this.$storage.setBackChangeCompany(false);
      this.$emit('closeModal');
    },
    setOpen(params) {
      this.isShow = params;
    },
    onTapCustomer(value) {
      this.selectedBranch = value;
    },
    async applySelectedCustomer() {
      Clevertap.onUserSelectCompany(this.selectedBranch);
      await this.getNumberBadge(this.selectedBranch?.id);
      if (this.previousSelectedCompanyId && this.previousSelectedCompanyId != this.selectedBranch?.id) {
        await this.getData(this.selectedBranch);
      } else {
        const selectedCompany = this.selectedCompany ? { ...this.selectedCompany } : { ...this.customer };
        selectedCompany.branches = [];
        await this.getData(this.selectedBranch);
      }
      if (this.customer.branches?.length > 0) {
        this.$emit('resetPath');
      }
      this.$emit('resetSearch');
      this.$emit('closeModal');
      if (this.currentPage === '/sale/cart' || this.prevPage === '/sale/cart') {
        this.$emit('refreshCart');
      }
      this.ionRouter.navigate({
        routerLink:
          this.currentPage === '/sale/cart' ||
          (this.currentPage === '/sale/select-customer' && this.prevPage === '/sale/cart')
            ? '/sale/cart'
            : this.currentPage === '/sale/invoices/select-invoices' ||
              this.prevPage === '/sale/invoices/select-invoices'
            ? '/sale/invoices/select-invoices'
            : '/sale/main/home'
      });
    },
    async getData(value) {
      await this[ACTIONS.UPDATE_SELECTED_CUSTOMER](value);

      // clear address_id
      await this.$storage.remove(STORAGE_KEYS.SELECTED_DELIVERY_ADDRESS);
      await this.$storage.remove(STORAGE_KEYS.SELECTED_BILLING_ADDRESS);

      await this.$storage.setSelectedCompany(value);
      await this.$storage.setSelectedCustomer(this.customer);
    },
    async changeCustomer() {
      this.$emit('closeModal');
      await this[ACTIONS_PAYMENT.REMOVE_SELECTED_PAYMENT_INVOICES]();
      await this.$storage.remove(STORAGE_KEYS.SELECTED_DELIVERY_ADDRESS);
      await this.$storage.remove(STORAGE_KEYS.SELECTED_BILLING_ADDRESS);
      await this.$storage.setBackChangeCompany(false);
      this.ionRouter.navigate({
        routerLink: '/sale/select-customer'
      });
    }
  }
});
</script>

<style scoped lang="scss">
.name {
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}
ion-label {
  white-space: normal !important;
  font-weight: 400;
  line-height: 22px;
}

#row {
  height: 50px;
  font-size: 20px;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 5px;
  border-radius: 20px;
}
.avatar {
  --border-radius: 9999px;
}
.skeleton-child {
  --border-radius: 8px;
}
.skeleton {
  align-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.image-default {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 17px;
}
.card-company {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.space-grey {
  height: 8px;
  width: 100vw;
  background: #eeeeee;
}
.foot-btn-outline {
  --border-radius: 10px;
  --color: #00676a;
}
.foot-btn {
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
}
.foot-btn[disabled] {
  --border-radius: 10px;
  --background: #9e9e9e;
  --color: white;
}
.box-label {
  background-color: var(--ion-color-text-error-100);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 7px;
}
</style>

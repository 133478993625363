<template>
  <ion-button @click="$router.push('/sale/notifications/all')" size="small" fill="clear" class="btn-notify">
    <ion-icon slot="icon-only" :icon="notificationsOutline" class="header-icon"></ion-icon>
    <div class="notify-count" v-show="numberBadge?.notifications > 0">
      <div class="notify-badge" :class="{ number: numberBadge?.notifications <= 9 }">
        {{ numberBadge?.notifications > 9 ? '9+' : numberBadge?.notifications }}
      </div>
    </div>
  </ion-button>
</template>

<script>
import { notificationsOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('sale/notification');

export default defineComponent({
  name: 'NotificationBadge',

  data() {
    return {
      isLoading: true
    };
  },
  setup() {
    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    return {
      notificationsOutline,
      setOpenLoading,
      isOpenLoadingref
    };
  },
  computed: {
    ...mapGetters(['numberBadge'])
  }
});
</script>

<style scoped lang="scss">
.header-icon {
  color: var(--ion-color-primary);
}

.btn-notify {
  position: relative;
  width: 32px;
  height: 32px;
}
.btn-notify::part(native) {
  padding: 0px;
}

.notify-count {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  position: absolute;
  background: #f44336;
}
.notify-badge {
  position: absolute;
  font-size: 8px;
  color: white;
  text-align: center;
  top: 4px;
  left: 3px;
  &.number {
    left: 5px;
  }
}
</style>
